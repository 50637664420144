import "./style.scss";

import { debounce } from "lodash";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import { CustomEase } from "gsap/CustomEase";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

gsap.registerPlugin(
  ScrollTrigger,
  SplitText,
  CustomEase,
  ScrollToPlugin,
  MorphSVGPlugin
);
MorphSVGPlugin.convertToPath("circle, rect, ellipse, line, polygon, polyline");
CustomEase.create("customEase", ".5,.08,0,.88");

import Glide from "@glidejs/glide";

import Headroom from "headroom.js";
// grab an element
var myElement = document.querySelector("header");
// construct an instance of Headroom, passing the element
var headroom = new Headroom(myElement);
// initialise
headroom.init();

ani3_or_img();

ani4_or_img();

cutoutSection();

if (document.querySelector("#home-hero")) {
  heroHeight();
  window.addEventListener("resize", _.debounce(heroHeight, 200));

  let theWidth = window.innerWidth;

  function heroHeight() {
    if (theWidth != window.innerWidth) {
      document.querySelector("#home-hero").style.height =
        window.innerHeight * 0.75 + "px";
    }

    theWidth = window.innerWidth;
  }
}

document.getElementById("open-menu").addEventListener("click", function () {
  gsap.to("#mobile-menu", { autoAlpha: 1 });
});

document.getElementById("close-menu").addEventListener("click", function () {
  gsap.to("#mobile-menu", { autoAlpha: 0, clearProps: true });
});

if (document.querySelector("#trusted")) {
  new Glide("#trusted", {
    type: "carousel",
    startAt: 0,
    perView: 4,
    gap: 0,
    autoplay: 3000,
    breakpoints: {
      600: {
        perView: 2,
      },
    },
  }).mount();
}

if (document.getElementById("scroll-down")) {
  document.getElementById("scroll-down").addEventListener("click", function () {
    gsap.to(window, {
      duration: 1,
      scrollTo: window.innerHeight,
      ease: "power4.out",
    });
  });
}

let accordions = document.querySelectorAll(".accordion button");
if (accordions) {
  accordions.forEach((element) => {
    element.addEventListener("click", function () {
      // if (element.classList.contains("active")) {
      // 	element.classList.remove("active");
      // 	gsap.to(element.nextElementSibling, { display: 'none' })
      // 	gsap.to(element.querySelector('.cornerline'), { morphSVG: ".cornerline" })
      // } else {
      // 	element.classList.add("active");
      // 	gsap.to(element.nextElementSibling, { display: 'block' })
      // 	gsap.to(element.querySelector('.cornerline'), { morphSVG: ".crossline" })
      // }

      this.classList.toggle("active");
      var panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
        panel.style.marginBottom = "";
        gsap.to(element.querySelector(".cornerline"), {
          morphSVG: ".cornerline",
          duration: 0.15,
        });
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
        panel.style.marginBottom = "1rem";
        gsap.to(element.querySelector(".cornerline"), {
          morphSVG: ".crossline",
          duration: 0.15,
        });
      }
    });
  });
}

let parallax = document.querySelectorAll(".parallax-section");
if (parallax) {
  parallax.forEach((element, i) => {
    if (i === 0) {
      ScrollTrigger.create({
        trigger: element.querySelector(".image-bg"),
        start: "top top",
        scrub: true,
        // markers: true,
        onEnter: () => {
          element.querySelector(".image-bg").classList.add("lg:bg-fixed");
        },
        onLeaveBack: () => {
          element.querySelector(".image-bg").classList.remove("lg:bg-fixed");
        },
      });
    }
  });
}

gsap.to("#mob-replacement-img2", {
  autoAlpha: 0,
  scrollTrigger: {
    trigger: "#mob-replacement-img2",
    start: "top 33%",
    end: "+=50%",
    scrub: true,
    // markers: true,
    pin: true,
    pinSpacing: false,
    invalidateOnRefresh: true,
  },
});

function ani3_or_img() {
  if (document.getElementById("animation3") && window.innerWidth > 991) {
    scroll_animation_3();
  }
}

// ani3_or_img()
window.addEventListener("resize", _.debounce(ani3_or_img, 200));

function scroll_animation_3() {
  const canvas = document.getElementById("animation3");
  const context = canvas.getContext("2d");

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  // const frameCount = 209;
  const frameCount = 163;

  // const currentFrame = index => (`https://parityqc.com/wp-content/uploads/images/anim1_small/anim1_${index.toString().padStart(5, '0')}.png`)
  const currentFrame = (index) =>
    `https://parityqc.com/wp-content/uploads/images/anim1_opt/anim1_${index
      .toString()
      .padStart(5, "0")}.png`;

  const preloadImages = () => {
    for (let i = 1; i < frameCount; i++) {
      const img = new Image();
      img.src = currentFrame(i);
    }
  };

  let imgWidth = 1920;
  let imgHeight = 1080;

  const images = [];
  const anim3 = { frame: 0 };

  for (let i = 0; i < frameCount; i++) {
    const img = new Image();
    img.src = currentFrame(i);
    images.push(img);
  }

  gsap.to(anim3, {
    frame: frameCount - 1,
    snap: "frame",
    ease: "none",
    scrollTrigger: {
      trigger: "#animation3-wrap",
      start: "top top",
      end: "+=200%",
      scrub: 0.5,
      // markers: true,
      pin: true,
      pinSpacing: true,
      onEnter: () => {
        gsap.to("#sect31", { autoAlpha: 0, duration: 0 });
      },
      onLeaveBack: () => {
        gsap.to("#sect31", { autoAlpha: 1, duration: 0 });
      },
    },
    onUpdate: render, // use animation onUpdate instead of scrollTrigger's onUpdate
  });

  images[0].onload = render;

  window.addEventListener("resize", () => {
    canvas.width = window.innerWidth;

    let setwinheight = window.innerHeight;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      setwinheight = window.outerHeight;
    }

    canvas.height = setwinheight;
    render();
  });

  function render() {
    context.clearRect(0, 0, canvas.width, canvas.height);
    var scale = Math.max(canvas.width / imgWidth, canvas.height / imgHeight);

    if (window.innerWidth < 768) {
      scale = scale * 0.5;
    }

    var x = canvas.width / 2 - (imgWidth / 2) * scale;
    var y = canvas.height / 2 - (imgHeight / 2) * scale;

    context.drawImage(
      images[anim3.frame],
      x,
      y,
      imgWidth * scale,
      imgHeight * scale
    );
  }

  preloadImages();
}

let mobimg = gsap.to("#mob-replacement-img", {
  autoAlpha: 0,
  scrollTrigger: {
    trigger: "#mob-replacement-img",
    start: "top 33%",
    end: "+=50%",
    scrub: true,
    // markers: true,
    pin: true,
    pinSpacing: false,
    invalidateOnRefresh: true,
  },
});

function ani4_or_img() {
  if (document.getElementById("animation4") && window.innerWidth > 991) {
    scroll_animation_4();
  } else if (window.innerWidth <= 991) {
  }
}

// ani4_or_img()
window.addEventListener("resize", _.debounce(ani4_or_img, 200));

function scroll_animation_4() {
  const canvas = document.getElementById("animation4");
  const context = canvas.getContext("2d");

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  const frameCount = 150;
  const currentFrame = (index) =>
    `https://parityqc.com/wp-content/uploads/images/anim2_opt/anim2_${index
      .toString()
      .padStart(5, "0")}.png`;

  const preloadImages = () => {
    for (let i = 1; i < frameCount; i++) {
      const img = new Image();
      img.src = currentFrame(i);
    }
  };

  // The actual sizes of the images px
  let imgWidth = 1920;
  let imgHeight = 1080;
  // let imgWidth = 600
  // let imgHeight = 338

  const images = [];
  const anim4 = {
    frame: 0,
  };

  for (let i = 0; i < frameCount; i++) {
    const img = new Image();
    img.src = currentFrame(i);
    images.push(img);
  }

  gsap.to(anim4, {
    frame: frameCount - 1,
    snap: "frame",
    ease: "none",
    scrollTrigger: {
      trigger: "#animation4-wrap",
      start: "top top",
      end: "+=150%",
      scrub: 0.5,
      // markers: true,
      pin: true,
      // pinSpacing: true,
    },
    onUpdate: render, // use animation onUpdate instead of scrollTrigger's onUpdate
  });

  images[0].onload = render;

  window.addEventListener("resize", () => {
    canvas.width = window.innerWidth;

    let setwinheight = document.documentElement.clientHeight;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      setwinheight = window.outerHeight;
    }

    canvas.height = setwinheight;
    render();
  });

  function render() {
    context.clearRect(0, 0, canvas.width, canvas.height);
    var scale = Math.max(canvas.width / imgWidth, canvas.height / imgHeight);

    if (window.innerWidth < 768) {
      scale = scale * 0.5;
    }

    var x = canvas.width / 2 - (imgWidth / 2) * scale;
    var y = canvas.height / 2 - (imgHeight / 2) * scale;
    context.drawImage(
      images[anim4.frame],
      x,
      y,
      imgWidth * scale,
      imgHeight * scale
    );
  }

  preloadImages();
}

// Change background colour for section 5
// ScrollTrigger.create({
// 	trigger: '#section-5',
// 	start: "top center",
// 	end: 'bottom bottom',
// 	// markers: true,
// 	onEnter: self => document.querySelector("body").classList.add("bg-parity-blue"),
// 	onEnterBack: self => document.querySelector("body").classList.add("bg-parity-blue"),
// 	onLeave: self => document.querySelector("body").classList.remove("bg-parity-blue"),
// 	onLeaveBack: self => document.querySelector("body").classList.remove("bg-parity-blue")
// });

function cutoutSection() {
  if (document.querySelector(".home")) {
    // gsap.set('#section-cutout, #cutout-wrapper, #section-last', {'height': window.innerHeight})
    document.querySelector("#section-cutout").style.height =
      document.documentElement.clientHeight + "px";
    document.querySelector("#cutout-wrapper").style.height =
      document.documentElement.clientHeight + "px";
    document.querySelector("#section-last").style.height =
      document.documentElement.clientHeight + "px";

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      document.querySelector("#section-cutout").style.height =
        document.documentElement.clientHeight * 1.2 + "px";
      document.querySelector("#cutout-wrapper").style.height =
        document.documentElement.clientHeight * 1.2 + "px";
      document.querySelector("#section-last").style.height =
        document.documentElement.clientHeight * 1.2 + "px";
    }

    var split2 = new SplitText("#section-last .split", {
      type: "words",
      wordsClass: "word++",
    });

    gsap
      .timeline({
        scrollTrigger: {
          trigger: "#section-cutout",
          start: "top top",
          end: "bottom top",
          // markers: true,
          pin: true,
          pinSpacing: true,
          scrub: true,
          id: "one",
        },
      })
      .to("#cutout-wrapper", { duration: 1, scale: 100, ease: "power4.in" }, 0)
      .from(
        "#section-last .split",
        { duration: 1, y: 20, ease: "customEase" },
        0.5
      )
      .from(
        split2.words,
        { duration: 1, autoAlpha: 0, ease: "customEase", stagger: 0.07 },
        0.5
      )
      .fromTo(
        "#cutout-wrapper",
        { autoAlpha: 1 },
        { autoAlpha: 0, ease: "power4.out" },
        0.9
      );

    cutout_sizes();
    window.addEventListener("resize", cutout_sizes);
    // window.addEventListener('scroll', cutout_sizes)

    let theWidth = window.innerWidth;

    function cutout_sizes() {
      if (theWidth != window.innerWidth) {
        let setwinheight = document.documentElement.clientHeight;
        // let setwinheight = window.innerHeight
        if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          )
        ) {
          // true for mobile device
          setwinheight = document.documentElement.clientHeight * 1.2;
        }

        let cutout_logo = document.getElementById("cutout"),
          cutout_logo_width = cutout_logo.offsetWidth / 2 - 2,
          cutout_logo_height = cutout_logo.offsetHeight / 2 - 2;

        document.getElementById("cutout1").style.height =
          setwinheight / 2 - cutout_logo_height + "px";
        document.getElementById("cutout2").style.width =
          document.body.clientWidth / 2 - cutout_logo_width + "px";
        document.getElementById("cutout3").style.height =
          setwinheight / 2 - cutout_logo_height + "px";
        document.getElementById("cutout4").style.width =
          document.body.clientWidth / 2 - cutout_logo_width + "px";
      }
      theWidth = window.innerWidth;
    }
  }
}

// Fade content in and out
let fade = document.querySelectorAll(".fade-in-up");
if (fade) {
  fade.forEach((element) => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: element,
          start: "top 90%",
          end: "bottom 90%",
          scrub: true,
          // markers: true,
          toggleActions: "play reverse play reverse",
        },
      })
      .to(element, { autoAlpha: 1 });

    gsap
      .timeline({
        scrollTrigger: {
          trigger: element,
          start: "top 10%",
          scrub: true,
          // markers: true,
          toggleActions: "play reverse play reverse",
        },
      })
      .to(element, { autoAlpha: 0 });
  });
}

// Variant to stagger second graph
let graph2 = document.querySelectorAll(".fade-in-up__graph2");
if (graph2) {
  graph2.forEach((element) => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top 60%",
        end: "bottom top",
        scrub: true,
        // markers: true,
        toggleActions: "play reverse play reverse",
      },
    });

    tl.to(element, { autoAlpha: 1, duration: 0.5 }).to(
      element,
      { autoAlpha: 0, duration: 0.5 },
      0.5
    );
  });
}

// var split = new SplitText("h1.split", {type: "words", wordsClass: "word++"});
// ScrollTrigger.create({
// 	trigger: "h1.split",
// 	start: "top bottom",
// 	// markers: true,
// 	onEnter: self => {

// 	}
// });

gsap
  .timeline({
    duration: 1,
    ease: "power1.out",
    onStart: () => {
      page_loaders();
    },
  })
  .to("header > div", { y: 0 })
  .set(".header__line", { autoAlpha: 1 }, 0.5)
  .from(".header__line", { width: 0, clearProps: "width" }, 0.5);

if (document.querySelector("h1.split")) {
  var split = new SplitText("h1.split", {
    type: "words",
    wordsClass: "overflow-hidden",
  });
  var split2 = new SplitText("h1.split", {
    type: "words",
    wordsClass: "transform translate-y-[150%] align-top",
  });
  gsap.set(".home h1.split", { display: "flex" });
}

function page_loaders() {
  if (
    document.querySelector(".home") ||
    document.querySelector(".blog") ||
    document.querySelector(".archive") ||
    document.querySelector(".page-template-page-blocks")
  ) {
    gsap
      .timeline({
        duration: 1,
        ease: "customEase",
      })
      // .from('h1.split', { y: 20 })
      .to("h1.split", { opacity: 1 })
      .to(split2.words, {
        y: 0,
        duration: 1,
        stagger: 0.07,
        onComplete: () => {
          gsap.to(".subtitle", { autoAlpha: 1 });
          gsap.fromTo(
            ".post-content",
            { autoAlpha: 1, y: 100 },
            { autoAlpha: 1, y: 0 }
          );

          if (
            document.querySelector(".blog") ||
            document.querySelector(".archive")
          ) {
            gsap.to("#blog-categories li", {
              autoAlpha: 1,
              stagger: 0.1,
              duration: 1,
              ease: "customEase",
            });

            ScrollTrigger.batch("#blog-posts a", {
              once: true,
              onEnter: (elements, triggers) => {
                gsap.fromTo(
                  elements,
                  {
                    autoAlpha: 0,
                    y: 40,
                  },
                  {
                    autoAlpha: 1,
                    y: 0,
                    stagger: 0.1,
                    duration: 1,
                    ease: "customEase",
                  }
                );
              },
            });
          }
        },
      })
      .fromTo("#scroll-down", { autoAlpha: 0 }, { autoAlpha: 1 }, "+=1");
  }

  if (document.querySelector("#contact-page")) {
    gsap.to("#contact-page a", {
      autoAlpha: 1,
      stagger: 0.2,
      delay: 1.5,
    });
  }

  if (
    document.querySelector(".single-post") ||
    document.querySelector(".page-template-page-careers")
  ) {
    gsap
      .timeline()
      .to(
        ".post-intro",
        {
          autoAlpha: 1,
          stagger: 0.2,
        },
        1
      )
      .to(
        "#post-image",
        {
          autoAlpha: 1,
          y: 0,
        },
        "-=.25"
      )
      .to(
        "#page-intro",
        {
          autoAlpha: 1,
          y: 0,
        },
        "-=.25"
      );
  }
}

if (document.querySelector(".glide")) {
  new Glide(".glide", {
    gap: 28,
    bound: true,
    perView: 5,
    breakpoints: {
      2000: {
        perView: 4,
        gap: 28,
      },
      1600: {
        perView: 3,
        gap: 28,
      },
      1024: {
        perView: 2,
        gap: 16,
      },
      600: {
        perView: 1,
        gap: 16,
      },
    },
  }).mount();

  ScrollTrigger.batch(".glide a", {
    once: true,
    // markers: true,
    onEnter: (elements, triggers) => {
      gsap.fromTo(
        elements,
        {
          autoAlpha: 0,
          y: 40,
        },
        {
          autoAlpha: 1,
          y: 0,
          stagger: 0.1,
          duration: 1,
          ease: "customEase",
        }
      );
    },
  });
}

let aboutHero = document.querySelector("#about-hero");
if (aboutHero) {
  var split = new SplitText("#about-hero", {
    type: "words",
    wordsClass: "overflow-hidden",
  });
  var split2 = new SplitText("#about-hero", {
    type: "words",
    wordsClass: "transform translate-y-[150%] align-top",
  });
  gsap.set("#about-hero", { display: "flex" });

  gsap
    .timeline({
      duration: 1,
      ease: "customEase",
    })
    // .from('h1.split', { y: 20 })
    .to(split2.words, { y: 0, duration: 0.5, stagger: 0.01 })
    .fromTo("#about-hero img", { autoAlpha: 0 }, { autoAlpha: 1 });

  function aboutHeroHeight() {
    if (window.innerWidth >= 768) {
      aboutHero.style.height = window.innerHeight - 80 + "px";
    } else {
      aboutHero.style.height = "auto";
    }
    aboutHero.style.paddingTop =
      document.querySelector("header").clientHeight + 40 + "px";
  }
  aboutHeroHeight();
  window.addEventListener("resize", _.debounce(aboutHeroHeight, 200));
}
